import type { ComponentType } from "react"
import { useEffect } from "react"

export function withCookiepro(Component): ComponentType {
    return (props) => {
        function clickBtn() {
            const cookieProButton = document.querySelector(
                ".ot-sdk-show-settings"
            )
            cookieProButton && cookieProButton.click()
        }

        function addScript() {
            const cookieProScript = document.createElement("script")
            cookieProScript.setAttribute(
                "src",
                "https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
            )
            cookieProScript.setAttribute("type", "text/javascript")
            cookieProScript.setAttribute("charset", "UTF-8")
            cookieProScript.setAttribute(
                "data-domain-script",
                "2d50dacc-42f8-4449-bfe2-bd85fbbc160a"
            )
            cookieProScript.onload = function () {
                setTimeout(clickBtn, 200)
            }
            document.head.appendChild(cookieProScript)
            window.OptanonWrapper = function () {}
        }

        function handleClick() {
            document.querySelector(
                "[data-domain-script='2d50dacc-42f8-4449-bfe2-bd85fbbc160a']"
            )
                ? clickBtn()
                : addScript()
        }

        useEffect(() => {
            function addBtn() {
                const cookieProButton = document.createElement("button")
                cookieProButton.setAttribute("class", "ot-sdk-show-settings")
                cookieProButton.style.display = "none"
                document.body.appendChild(cookieProButton)
            }
            addBtn()
        }, [])
        return <Component {...props} onClick={handleClick} />
    }
}
